.ACH-BadgeCon{
    margin: 0 !important;
}
.ACH-TreeDetalisCon {
    margin: 25px 25px 15px !important;
}
.ACH-TreeDetalisCon .col{
    padding: 0;
}
.ACH-TreeDetalisImg-lft{
    margin-right: 15px;
}
.ACH-TreeDetalisCon .col .row {
    border-bottom: 1px solid #95ADB9;
    padding-bottom: 5px;
    margin: 0 0 9px !important;
    text-align: right;
    padding-right: 9px;
}
.col .Ach-detail-Crclbar.row{
    border-bottom: none !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}
.Ach-detail-Crclbar .Ach-detail-cp{
    position: absolute;
}
.Ach-detail-Crclbar .Ach-detail-cp > div:first-child{
    position: absolute;
    bottom: 0;
}
.ACH-TreeDetalisCon .col .row .col p{
    font-size: 13px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 0px;
}
.ACH-TreeDetalis-ryt{
    margin-top: 101px;
}
.ACH-TreeDetalisCon img{
    border-radius: 50%;
    background: #F1F5F6;
    width: 160px;
    padding: 12px 12px 0;
}
.ACH-TreeDetalisCon .col .row .col span{
    font-size: 19px;
    color: #00A0E1;
    font-weight: 500;
}
.ACH-TreeDetalisCon .col .row:last-child {
    border-bottom: none;
    margin-bottom: 0 !important;
    padding-bottom: 0;
}
.ACH-BadgeCon.row {
    background: #CEDCE3;
    padding: 80px 25px 50px;
}
.ACH-BadgeCon .col{
    padding: 0;
    text-align: center;
}
/* .ACH-BadgeCon .col:first-child,
.ACH-BadgeCon .col:nth-child(2){
    margin-right: 25px;
} */
.ACH-BadgeCon p {
    font-size: 11px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 5px;
}
.ACH-BadgeImgCon {
    background: #fff;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    border-radius: 50%;
    position: relative;
}
.ACH-BadgeImg span {
    position: absolute;
    top: -6px;
    right: 0;
    background: #009EDF;
    border-radius: 50%;
    color: #fff;
    font-family: arial;
    font-size: 10px;
    width: 20px;
    padding: 3px;
}
.ACH-TreeDetalisCon .CircularProgressbarWithChildren-outr{
    width: 69px;
    vertical-align: middle;
    background: #F1F5F6;
    border-radius: 50%;
    padding: 7px;
    /*position: relative;
    right: 0;
    top: -60px;*/
}
.ACH-TreeDetalisCon .DriverScore_Txt {
    font-size: 14px;
    width: 65px;
    position: absolute;
    /*top: -5px;*/
    right: 25px;
    margin-top: -2px !important;
}
.Ach-detail-placing{
    /*position: relative;
    top: 22px;*/
}
