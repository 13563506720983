/*Base CSS*/
@font-face {
  font-family: Arial;
  src: local('Arial');
  font-weight: normal;
}
html,body {
  padding: 0;
  margin: 0;
  min-width: 300px;
}
body {
  font-family: "Arial";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: top;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-tap-highlight-color: transparent;
  -khtml-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
*:focus {
  border: none;
  outline: none;
}
*:hover {
  outline: 0;
}
*::-moz-focus-inner {
  border: none;
  padding: 0;
}
a,a:link,a:visited {
  text-decoration: none;
  cursor: pointer;
}
a {
  text-decoration: none;
  cursor: inherit;
  color: #ffffff;
  font-family: "Arial";
}
p{
  font-family: "Arial";  
}
a:hover {
  text-decoration: none;
}
img,svg {
  max-width: 100%;
  height: auto;
}

h1,h2,h3,h4,h5,h6 {
  color: #ffffff;
  font-family: "Arial";
  padding: 0;
  margin: 0;
}

/* Common Bootstrap CSS Overide */
.container {
    margin: 0 auto !important;
    padding: 0 !important;
  }
  @media (min-width: 768px){
    .container {
      max-width: 100% !important;
    }
  }
  @media (min-width: 576px){
    .container {
      max-width: 100% !important;
    }
  }
  .MainCon-outr{
    position: relative;
  }
  .clearfix{
    clear: both;
  }
  .btn.focus, .btn:focus {
      box-shadow: none !important;
  }
  /*Fixed scroll bar*/
  .header-Container {
    position: fixed;
    width: 100%;
    z-index: 3; 
  }
  .MainContent {
    padding-top: 93px;
  }
  #NoContent-Con.MainContent{
    padding-top: 55px;
  }

  
  /*Hamburger Manu */
  .navbar {
    padding: 0 !important;
    background: #005591 0% 0% no-repeat padding-box;
    opacity: 1;
  }
  #nav-icon1{
    margin: 16px 12px 16px 25px !important;
    width: 1.5em !important;
    top: -2px !important;
  }
  #nav-icon1 span {
    border-radius: 25px;
    height: 2px;
  }
  .navbar-brand{
    color: #fff !important;
    font-family: Arial;
    width: calc(100% - 66px);
    margin: 0!important;
    padding: 0 !important;    
    font-size: 18px !important;
  }
  .navbar-brand img{
    width: 73px;
  }
  .navbar-collapse{
    background: #D2E1EB;
    border-bottom: 1px solid #fff;
  }
  ul.navbar-nav {
    padding: 10px 30px;
  }
  #nav-icon1 span:nth-child(1) {
    top: 5px !important;
  }
  #nav-icon1 span:nth-child(2) {
    top: 13px !important;
  }
  #nav-icon1 span:nth-child(3) {
    top: 22px !important;
  }
  .hamburger-button__checkbox:checked+#nav-icon1 span:nth-child(1) {
    top: 16px !important;
  }
  .hamburger-button__checkbox:checked+#nav-icon1 span:nth-child(3) {
    top: 16px !important;
  }
  .nav-item a{
    font-size: 14px;
  }
  .nav-item.active a{
    color: #005591;
  }
  
  /*Menu Css starts*/
  
  ul.menu-OuterCon {
    background: #D2E1EB;
    padding: 9px 25px 2px;
    margin-bottom: 3px;
  }
  ul.menu-OuterCon.German li{
    margin: 0 3.4VW !important;
  }
  ul.menu-OuterCon.English li{
    margin: 0 1.9VW !important;
  }
  ul.menu-OuterCon li{
    list-style: none;
    display: inline-block;
    margin-right: 3.2VW;
  }
  ul.menu-OuterCon li:last-child{
    margin-right: 0;
  }
  ul.menu-OuterCon li:first-child{
    margin-left: 0;
  }
  ul.menu-OuterCon li a{
    color: #005591;
    font-size: 3.7vw;
    line-height: normal;
    text-transform: capitalize;
    text-decoration: none;
  }
  ul.menu-OuterCon li a.active{
    color: #00A0E1 !important;
  }
  @media (min-width: 320px){
  
  }
  
  @media (min-width: 375px){
  
  }
  
  @media (min-width: 425px){
  
  }
  @media (min-width: 768px){
  
  }

/*Dashboard Carousel */
 .carousel-indicators li{
    width:10px!important;
    height:10px!important;
    border-radius: 50%;
}
.carousel-control-next, .carousel-control-prev{
    align-items: initial!important;
}

.progress-bar{
    background-color: #cedce3 !important;
    height:20px;
}
.navbar-light .navbar-nav .nav-link {
  color: #000;
}

.tripScore {
  font-size: 13px;
  position: absolute;
  top: 12px;
  right: 0;
  font-weight: 600;
  color: #005591;
}
ul.menu-OuterCon li a.active{
  color: #130057
}

/*Hamburger Menu*/ 
#page-wrap {
  text-align: center;
  overflow: auto;
  padding: 0 !important;
  background: #005591 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 55px;
}
#page-wrap img{
  width: 67px;
  position: absolute;
  left: 68px;
  top: 16px;
}

/* Individual item */
.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
  text-decoration: none;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
  text-decoration: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 20px;
  left: 30px;
  top: 16px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
  height: 3px !important;
  border-radius: 1px !important;
  opacity: 1 !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 14px;
  line-height: normal;
  opacity: 0.9;
  font-family: Arial;
}
/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: /*rgba(0, 0, 0, 0.3)*/ none !important;
}
.bm-menu-wrap{
  transition: all 0.2s ease 0.2s !important;
  top: 55px !important;
  width: 225px !important;
}
.bm-overlay{
  transition: all 0.2s ease 0.2s !important;  
}
.bm-item.active {
  color: #00A0E1 !important;
  text-decoration: none;
}
.cmnstatic-pageCon {
  margin: 25px;
  color: #005591;
  font-size: 12px;
  line-height: normal;
}
.cmnstatic-pageCon h1{
  color: #005591;
  font-size: 16px;
  font-family: Arial;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 0px;
}
.cmnstatic-pageCon p{
  margin-bottom: 25px;
  font-family: arial;
  line-height: 18px;
}
.cmnstatic-pageCon div{
  margin-bottom: 25px;
}
.cmnstatic-pageCon a{
  color: #26AAE4;
  text-decoration: none;
}