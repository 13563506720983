.TipBox_Content{
    padding: 10px;
    background: #E7EEF0 0% 0% no-repeat padding-box;
    border: 2px solid #D2E1EB;
    margin-left: 25px;
    margin-right: 25px;
}
.TipHeading{
    font-size: 12px;
    color: #000000;
    margin-bottom: 0;
}
.HeaderBox_content{
    background: #E8F0F5 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 10px;    
}
.HeaderTripDate{
     text-align:center;
     font-family:Arial;
     font-size: 14px;
     color:#000000;    
    margin-bottom: 0;
}
.HeaderTripNumber{
    text-align: center;
    font-family:'Arial'; 
    font-size: 11px;
    color:#000000;   
    margin-bottom: 0;    
    margin-top: 3px;    
    letter-spacing: 0.3px;
}
.TripScore_content{
    margin: 12px 25px !important;  
}
.TripScore_content .col-5:first-child{
    padding-left: 0;
}
.TripScore_content .col-2:nth-child(2){
    padding: 0;
    text-align: center;
    top: 8vw;
}
.TripScore_content .col-7:last-child{
    padding-right: 0;
    top: 4.5vw;
    padding-left: 0;
}
.TripScore_Driven_Distance{
    text-align: right;
    font-size:  12px;
    color: #3F3F3F;
    opacity: 1;
    margin-bottom: 3px;
}
.TripScore_Driven_Distance span {
    padding: 0 2px;
}
.TripScore_Txt_Fuel_Consumed{
    text-align: right;
    font-size:  12px;
    letter-spacing: 0;
    color: #3F3F3F;
    opacity: 1; 
    margin-bottom: 3px;
}
.TripScore_Fuel_Consumed{
    text-align: right;
    font-size: 14px;
    letter-spacing: 0;
    color: #00A0E1;  
    margin-bottom: 5px;
}
.Trip_Score{
    font-size: 12px;
    color: #005591;
    margin-top: 20px;
    text-align: left;
}
.DB-furtherdetbtn-Con button{
    width: calc(100% - 50px);
    border-radius: 0;
    line-height: inherit;
    font-size: 17px;
}
.btn-primary {
    background-color: #00A0E1 !important;
    border-color: #00A0E1 !important;
}

/* Carousel */
.carousel-indicators li{
    background-color: #CBCCCE !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
}
.carousel-indicators .active {
    background-color: #2FBCDF !important;
}
.carousel-indicators {
  bottom: -55px !important;
}
.carousel-control-next,
.carousel-control-prev {
    top: 22px !important;
}
.slick-dots {
    bottom: -40px !important;
}
.slick-dots li {
    margin: 0 1px !important;
}
.slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: #48BDEB !important;
}
.slick-dots li button:before {
    font-size: 10px !important;
    color: #48BDEB !important;
}
.slick-prev, .slick-next {
    display: none;
}

/*Circular progressive bar*/
.CircularProgressbarWithChildren-Con{
    padding: 5vw;
   
}
.CircularProgressbar.CircularProgressbarWithChildren-Con .CircularProgressbar-path {
    stroke: #B8CDD7 !important;
    opacity: 1;
}
.DriverScore_Txt{
    font-size: 14px;
    margin-top: -5px;
    color: #48BDEB;
}
.AverageTripScore_Txt{
    font-size: 10px;
    margin-top: -5px;
    color: #B8CDD7;
}
.DB_ProgressiveCon{
    margin: 25px;
}
.DB_ProgressiveCon_list{    
    margin-bottom: 15px;
}
.DB_ProgressiveCon_list:last-child{   
    margin-bottom: 0px;
}
.DB_ProgressiveCon p{
    margin-bottom: 5px;
    font-size: 12px;
}

/*Leaf*/
.UOM-details{
    margin-bottom: 5px;
}
.DB-leafCon{
    margin: 0 !important;
}
.DB-leafinnr{
    width: 148px;
    float: right;
}
#SupVishidden{
    visibility: hidden;
}
.DB-leaf-counter {
    border-radius: 50%;
    text-align: center;
    color: #fff;
    width: 23px !important;
    height: 22px;
    line-height: 21px;
    font-size: 11px;
    margin-top: 0px;
    border: 1px solid #fff;
    float: left;
}
.DB-leaf-Green{
    background: #009B3C ;    
    padding-right: 0 !important;
}
.DB-leaf-Orange{
    background: #DC7800;
    padding-right: 3px !important;
}
.DB-leaf-ImgCon{
    line-height: normal;
    float: left;
    width: 125px;
}
.DB-leaf-ImgCon img{
    margin-left: 6px;
    width: 19px;
    display: inline-block !important;
    height: auto;
}
.disableArrow{
    display: none
}

/* For slider */
.slick-prev{
    left:0px;
  }
  .slick-next{
    right: 0px;
  }
  /* arrow for carousel */
  
  img.arrow {
    outline: 0;
    border: none;
    width: 13px;
    height: auto;
    margin: 5px auto;
  }