.leaflet-container {
    overflow: hidden;
    position: initial;
    height: 217px;
    width: 100%;
    max-width: 768px;
}
.pin{
width: 30px;
height: 30px;
border-radius: 50% 50% 50% 0;
color: #89849b;
position: absolute;
transform: rotate(-45deg);
left: 50%;
top: 50%;
margin: -20px 0 0 -20px;
animation-name: bounce;
animation-fill-mode: both;
animation-duration: 1s;

}
.serial_background{
    border-radius: 50%;
    border: 1px solid #cedce3;
    font-size: 9px;
    padding: 1px 4px;
}
.incident_seperator{
    border: 0;
    height: 1px;
    background: #333;
    background-image: -webkit-linear-gradient(left, #ccc, #333, #ccc);
    background-image: -moz-linear-gradient(left, #ccc, #333, #ccc);
    background-image: -ms-linear-gradient(left, #ccc, #333, #ccc);
    background-image: -o-linear-gradient(left, #ccc, #333, #ccc);
}
/* .leaflet-top{
    visibility: hidden;
} */
.INP_progressiveCon{
    margin: 25px 25px 0;
    height: 158px !important;
    overflow-x: scroll;
} 
.leaflet-touch .leaflet-bar a {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 16px;
}
.leaflet-top {
    bottom: 20px;
    right: 10px;
    left: auto !important;
    top: auto !important;
}

.INP_progressiveCon .row{
    margin: 0 0 20px;
    height: 15px;
}
.leaflet-popup-content{
    width: 135px;
    height: 51px;
    line-height: 16px;
    font-size: 8.5px;
    margin: 20px 15px;
    font-family: arial;
    color: #000;
    font-weight: 600;
}
.leaflet-container a.leaflet-popup-close-button {
    margin: 9px 8px 0 0;
    padding: 0;
    width: 15px;
    height: 15px;
    color: #030303;
    font-size: 11px;
    border: 1px solid #030303;
    border-radius: 50%;
}
.incidents_content{
    margin: 12px 25px !important;  
}
.incidents_content .col-5:first-child{
    padding-left: 0;
}
.incidents_content .col-2:nth-child(2){
    padding: 0;
    text-align: center;
    top: 8vw;
}
.incidents_content .col-5:last-child{
    padding-right: 0;
    top: 4.5vw;
    padding-left: 0;
}
.slick-prev, .slick-next{
    top: 30px;
    z-index: 1;
    width: 30px;
}
.slick-prev{
    background-image: url('../../assets/Arrow_left.png') !important;
    background-position: 100% center!important;
    background-repeat: no-repeat!important;
    background-size: 13px auto!important;
    height: 30px;
    left: 3px;
}
.slick-next{
    background-image: url('../../assets/Arrow.png')!important;
    background-position: 100% center!important;
    background-repeat: no-repeat!important;
    background-size: 13px auto!important;
    height: 30px;
    right: 21px;
}
.slick-disabled{
    display: none !important;
}
.slick-prev:before,
.slick-next:before{
    content: '' !important;
}