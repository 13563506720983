.bGraph-con{
    margin: 15px 0;
}
.bGraph-con ul{
    margin: 0 25px 25px;
    border-bottom: 1px solid #D2E1EB;
    padding-bottom: 6px;
}
.bGraph-con-chart {
    margin: 0 15px;
}
.bGraph-con .list-group-item {
    position: relative;
    display: inline-block;
    background-color: transparent !important;
    font-size: 13px;
    font-family: Arial;
    line-height: normal;
    border: none !important;
    border-radius: 0 !important;
    margin: 0 20px 0px 0 !important;
    padding: 0 !important;
    background: transparent !important;
    color: #000;
}
.list-group-item.active {
    color: #00A0E1 !important;
    position: relative;
}
.list-group-item.active span {
    display: block;
    position: absolute;
    border-bottom: 2px solid #005591 !important;
    width: 100%;
    bottom: -8px;
    line-height: normal;
}